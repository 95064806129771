import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CButton, CCol } from "@coreui/react";
import { AuthContext } from "../contexts/AuthContext";
import { db } from "../firebaseConfig";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";

import default_profile_image from "../assets/images/default_profile_image.png";
import CIcon from "@coreui/icons-react";
import { cilChevronCircleLeftAlt } from "@coreui/icons";

const ProfileNavbar = ({ onTabChange,widthVal,setWidthVal }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [threads, setThreads] = useState([]);


  useEffect(() => {
    const fetchThreads = async () => {
      if (user) {
        const userThreadsRef = collection(db, `users/${user.uid}/threads`);
        const threadSnapshot = await getDocs(userThreadsRef);
        const threadsList = threadSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setThreads(threadsList);
      }
    };

    fetchThreads();
  }, [user]);

  const handleNewTopicClick = (e) => {
    e.preventDefault();
    navigate("/yapay-zeka-ile-arama");
  };

  const handleDeleteThread = async (threadId) => {
    try {
      await deleteDoc(doc(db, `users/${user.uid}/threads`, threadId));
      setThreads(threads.filter((thread) => thread.id !== threadId));
    } catch (error) {}
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        height: "100vh",
        width: widthVal,
        transition: "width 0.4s ease",
        backgroundColor: "#f8f9fa",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "80px",
          left: widthVal === "250px" ? "93%" : "30px",
          cursor:'pointer',
          transition: "left 0.5s ease", // Animasyon geçiş süresi
        }}
      >
        <div
          onClick={() => setWidthVal(widthVal === "250px" ? "50px" : "250px")}
          style={{
            borderRadius: "9999px",
            height: "37.5px",
            width: "37.5px",
            background: "white",
            transition: "transform 0.5s ease", // Buton genişleme animasyonu
          }}
        >
          <CIcon
            icon={cilChevronCircleLeftAlt}
            size="4xl"
            color="rgb(107, 27, 39)"
            style={{
              transition: "transform 0.5s ease", // Ok simgesi animasyonu
              transform:
                widthVal === "250px" ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </div>
      </div>
      {/*     <Link to="/dokuman-arama-page" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logo} alt="Logo" style={{ marginBottom: '20px', width: '60%' }} />
                </Link> */}

      {/* Navigation Links */}
      {widthVal === "250px" && (
        <div
          style={{
            opacity: widthVal === "250px" ? 1 : 0,
            transition: "opacity 0.5s ease", // Opacity ile yumuşak görünüm
          }}
        >
          <CButton
            className="nav-link-profile"
            style={{ marginTop: "80px",minWidth:'200px', transition: "all 0.5s ease" }}
            onClick={() => onTabChange("favorites")}
          >
            Favorilerim
          </CButton>
          <CButton className="nav-link-profile">İstatistiklerim (soon)</CButton>

          {user ? (
            <div style={{
                position: "absolute",
                width:"100%",
                bottom: 0,
                left:0

            }}>
            <Link
              to={"/profile"}
              onClick={() => onTabChange("profile")}
              style={{

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",

                textDecoration: "none", // Ensures no underline for the link
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  height: "100%",

                  cursor: "pointer",
                }}
              >
                <img
                  src={user.photoURL ? user.photoURL : default_profile_image}
                  alt="Profile"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <span style={{ color: "black" }}>
                  {user.displayName || user.email || "Kullanıcı"}
                </span>
              </div>
            </Link>
            </div>

          ) : (
            <Link to="/login" style={{ width: "100%" }}>
              <CButton color="primary" style={{ width: "100%" }}>
                Giriş Yap
              </CButton>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileNavbar;
