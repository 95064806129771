import { CCard, CCardHeader, CCol, CContainer, CRow } from "@coreui/react";
import React, { useContext, useState } from "react";
import GreetingMessage from "../components/Greeting";
import { AuthContext } from "../contexts/AuthContext";
import searchService from "../service/SearchService";
import "./ImprovedSearchView.css";
import SelectedDocumentView from "./SelectedDocumentView";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Button2 from "../components/ui/Button2/Button2";

const MevzuatView = () => {
  const user = useContext(AuthContext);
  const [staticResults, setStaticResults] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(null);

  const fetchDocuments = async (documentType) => {
    setIsLoading(true);
    try {
      const response = searchService.fetchTopDocuments(documentType);
      setResults(response.data.data);
      setStaticResults(response.data.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = (documentType) => {
    setActiveButton(documentType);
    fetchDocuments(documentType);
  };

  const sendUserToGOV = (link) => {
    window.open(link, "_blank");
  };

  const groupResultsByDate = (results) => {
    return results.reduce((groups, result) => {
      const date = result.resmiGazeteTarihi.trim();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(result);
      return groups;
    }, {});
  };

  const renderGroupedResults = (groupedResults) => (
    <CContainer className="pb-5">
      {Object.keys(groupedResults).map((date) => (
        <div key={date} style={{ marginBottom: "15px" }}>
          <h3>Resmi Gazete Tarihi: {date}</h3>
          {groupedResults[date].map((result, index) => (
            <CCard
              onClick={() => sendUserToGOV(result.link)}
              className="mb-3"
              key={index}
            >
              <CCardHeader
                as="h3"
                style={{
                  padding: "20px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ maxWidth: "80%" }}>
                    <h3
                      style={{
                        marginRight: "20px",
                        marginBottom: "5px",
                        fontWeight: "600",
                        fontSize: "12px",
                        width: "100%",
                        minHeight: "60px",
                      }}
                    >
                      {result.mevAdi.trim()}
                    </h3>
                    <h4
                      style={{ margin: "0", color: "#555", fontSize: "13px" }}
                    >
                      Mevzuat Numarası: {result.mevzuatNo.trim()}, Tertip:{" "}
                      {result.mevzuatTertip.trim()}, Resmi Gazete Tarihi:{" "}
                      {result.resmiGazeteTarihi.trim()}, Sayısı:{" "}
                      {result.resmiGazeteSayisi.trim()}
                    </h4>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div
                      style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                        fontWeight: "600",
                      }}
                    >
                      {result.mevzuat_type_name.trim()}
                    </div>
                    <div style={{ fontSize: "13px", color: "#888" }}>
                      Kabul Tarihi:{" "}
                      {result.kabulTarih
                        ? result.kabulTarih.trim()
                        : result.date.trim()}
                    </div>
                  </div>
                </div>
              </CCardHeader>
            </CCard>
          ))}
        </div>
      ))}
    </CContainer>
  );

  const renderSearchResults = () => {
    const groupedResults = groupResultsByDate(results);
    return renderGroupedResults(groupedResults);
  };

  return (
    <div>
      <CContainer className="py-5">
        <CRow className="justify-content-center">
          <CCol
            md="lg"
            style={{
              justifyContent: "center",
              display: "flex",
              maxWidth: "80%",
              marginBottom: "30px",
            }}
          >
            <GreetingMessage user={user} />
          </CCol>
        </CRow>

        <Swiper
          slidesPerView={3}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20, // Adjusted for better scaling
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor: activeButton === "mevzuat" ? "#6B1A28" : "",
                color: activeButton === "mevzuat" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("mevzuat")}
            >
              Tümü
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",

                backgroundColor: activeButton === "kanunlar" ? "#6B1A28" : "",
                color: activeButton === "kanunlar" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("kanunlar")}
            >
              Kanunlar
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "cumhurbaskanligi_kararnameleri"
                    ? "#6B1A28"
                    : "",
                color:
                  activeButton === "cumhurbaskanligi_kararnameleri"
                    ? "#EFE8E1"
                    : "",
              }}
              onClick={() =>
                handleButtonClick("cumhurbaskanligi_kararnameleri")
              }
            >
              C. Kararnameleri
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "yonetmelikler" ? "#6B1A28" : "",
                color: activeButton === "yonetmelikler" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("yonetmelikler")}
            >
              C. ve Bakanlar Kurulu Yönetmelikleri
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "cumhurbaskani_kararlari" ? "#6B1A28" : "",
                color:
                  activeButton === "cumhurbaskani_kararlari" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("cumhurbaskani_kararlari")}
            >
              C. Kararları
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "cumhurbaskanligi_genelgeleri"
                    ? "#6B1A28"
                    : "",
                color:
                  activeButton === "cumhurbaskanligi_genelgeleri"
                    ? "#EFE8E1"
                    : "",
              }}
              onClick={() => handleButtonClick("cumhurbaskanligi_genelgeleri")}
            >
              C. Genelgeleri
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "kanun_hukmunde_kararnameler"
                    ? "#6B1A28"
                    : "",
                color:
                  activeButton === "kanun_hukmunde_kararnameler"
                    ? "#EFE8E1"
                    : "",
              }}
              onClick={() => handleButtonClick("kanun_hukmunde_kararnameler")}
            >
              KHK
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor: activeButton === "tuzukler" ? "#6B1A28" : "",
                color: activeButton === "tuzukler" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("tuzukler")}
            >
              Tüzükler
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor:
                  activeButton === "kurum_yonetmelikleri" ? "#6B1A28" : "",
                color: activeButton === "kurum_yonetmelikleri" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("kurum_yonetmelikleri")}
            >
              Kurum, Kuruluş ve Üniversite Yön.
            </Button2>
          </SwiperSlide>
          <SwiperSlide>
            <Button2
              style={{
                fontSize: "12px",
                width: "100%",
                minHeight: "60px",
                backgroundColor: activeButton === "tebligler" ? "#6B1A28" : "",
                color: activeButton === "tebligler" ? "#EFE8E1" : "",
              }}
              onClick={() => handleButtonClick("tebligler")}
            >
              Tebliğler
            </Button2>
          </SwiperSlide>
          <div className="swiper-button-prev">
            <svg

              viewBox="-3 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="icomoon-ignore"> </g>{" "}
                <path
                  d="M13.114 2.887c-7.243 0-13.114 5.871-13.114 13.113s5.871 13.113 13.114 13.113c7.242 0 13.112-5.871 13.112-13.113s-5.87-13.113-13.112-13.113zM13.114 28.064c-6.653 0-12.065-5.412-12.065-12.064s5.412-12.063 12.065-12.063c6.652 0 12.063 5.412 12.063 12.063s-5.411 12.064-12.063 12.064z"
                  fill="#000000"
                >
                  {" "}
                </path>{" "}
                <path
                  d="M12.318 10.363l-0.742-0.742-6.379 6.379 6.379 6.379 0.742-0.742-5.113-5.113h12.726v-1.049h-12.726z"
                  fill="#000000"
                >
                  {" "}
                </path>{" "}
              </g>
            </svg>
          </div>

          <div  className="swiper-button-next">
            <svg
              viewBox="-3 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth={0} />
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g id="icomoon-ignore"> </g>{" "}
                <path
                  d="M13.11 29.113c7.243 0 13.113-5.871 13.113-13.113s-5.87-13.113-13.113-13.113c-7.242 0-13.113 5.871-13.113 13.113s5.871 13.113 13.113 13.113zM13.11 3.936c6.652 0 12.064 5.412 12.064 12.064s-5.412 12.064-12.064 12.064c-6.653 0-12.064-5.412-12.064-12.064s5.411-12.064 12.064-12.064z"
                  fill="#000000"
                >
                  {" "}
                </path>{" "}
                <path
                  d="M13.906 21.637l0.742 0.742 6.378-6.379-6.378-6.379-0.742 0.742 5.112 5.112h-12.727v1.049h12.727z"
                  fill="#000000"
                >
                  {" "}
                </path>{" "}
              </g>
            </svg>
          </div>
        </Swiper>
      </CContainer>
      {selectedDocument ? (
        <SelectedDocumentView selectedDocument={selectedDocument} />
      ) : (
        renderSearchResults()
      )}
    </div>
  );
};

export default MevzuatView;
