import React, { useState } from "react";
import { CContainer, CRow, CCol, CButton } from "@coreui/react";
import ProfileNavbar from "./ProfileNavbar";
import ProfileView from "./ProfileView";
import FavoritesView from "./FavoritesView";
import StatisticsView from "./StatisticsView";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [widthVal, setWidthVal] = useState("250px");

  return (
    <CContainer className="full-height">
      <div className="d-flex">
        <div>
          <ProfileNavbar
            setWidthVal={setWidthVal}
            widthVal={widthVal}
            onTabChange={handleTabChange}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            marginLeft: widthVal,
            transition: "margin-left 0.4s ease", // marginLeft'e geçiş uygulandı
            overflow: "hidden",
            paddingInline: "2%",
          }}
        >
          <CContainer className="reference-documents-container">
            {activeTab === "profile" && <ProfileView /> }
            {activeTab === "favorites" && <FavoritesView />}
            {activeTab === "statistics" && <StatisticsView />}
          </CContainer>
        </div>
      </div>
    </CContainer>
  );
};

export default Profile;
