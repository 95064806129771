export const NavLinks = [
  {
    title: "Anasayfa",
    path: "https://www.leagleapp.com",
  },
  {
    title: "Hakkımızda",
    path: "https://www.leagleapp.com/about",
  },
  {
    title: "Profilim",
    path: "/profile",
  },
  {
    title: "Leagle Doküman Arama",
    path: "/dokuman-arama-page",
  },
  
  {
    title: "Yapay Zeka ile Arama",
    path: "/yapay-zeka-ile-arama",
  },
  {
    title: "Günce",
    path: "/mevzuat",
  },
];
