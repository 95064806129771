import axiosClient from "../client/LeagleClient";
import firebaseClient from "../client/FirebaseClient";


const searchService = {

    async searchDocuments(query, category) {
        await firebaseClient.saveSearchToFirestore(query);
        const url = `/dokuman_arama_motoru/ai_search/?query=${query}&index=${category}`;
        const response = await axiosClient.get(url);
        return response.data;
    },

    async fetchTopDocuments(documentType) {
        try {
            const endpoint = `/dokuman_arama_motoru/top-10-${documentType}/`;
            const response = await axiosClient.get(endpoint);
            return response.data.data;
        } catch (error) {
            console.error(`Error fetching top ${documentType} documents:`, error);
            throw error;
        }
    },


};

export default searchService;
